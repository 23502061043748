import React from 'react';
import { StyledLink } from 'baseui/link';
import { Link } from 'react-router-dom';

// Use the same style as StyledLink component
const RRLink = ({ children, ...rest }) => (
  <StyledLink $as={Link} {...rest} style={{ textDecoration: 'none' }}>
    {children}
  </StyledLink>
);

export default RRLink;
