import React from 'react';

import {
  StatefulTooltip,
  PLACEMENT,
  StatefulTooltipProps,
} from 'baseui/tooltip';
import { useStyletron } from 'baseui';
import { Button, SHAPE } from 'baseui/button';
import { MdInfoOutline } from 'react-icons/md';

type InfoIconProps = StatefulTooltipProps & {
  info: string | React.ReactElement;
};

const InfoIcon = ({ info, ...rest }: InfoIconProps) => {
  const [, theme] = useStyletron();

  return (
    <StatefulTooltip
      content={info}
      placement={PLACEMENT.top}
      ignoreBoundary
      showArrow
      {...rest}
    >
      <Button
        isSelected
        shape={SHAPE.round}
        $as="div"
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: 'transparent',
              paddingTop: '0px',
              paddingBottom: '0px',
            },
          },
        }}
      >
        <MdInfoOutline
          style={{
            fontSize: '18px',
            color: theme.colors.primary,
          }}
        />
      </Button>
    </StatefulTooltip>
  );
};

export default InfoIcon;
