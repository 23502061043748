import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthUser } from './sessionSlice';
import { initializeRiskProfile } from '../RiskProfile/riskProfileSlice';

import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  const WithAuthFirebase = props => {
    const dispatch = useDispatch();
    dispatch(setAuthUser(JSON.parse(localStorage.getItem('authUser'))));

    useEffect(() => {
      // Set authUser and riskProfile on authentication
      const listener = props.firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          dispatch(setAuthUser(authUser));
          dispatch(initializeRiskProfile(props.firebase, authUser));
        },
        () => {
          localStorage.removeItem('authUser');
          dispatch(setAuthUser(null));
        },
      );
      return () => listener();
    }, [props.firebase, dispatch]);

    return <Component {...props} />;
  };
  return withFirebase(WithAuthFirebase);
};

export default withAuthentication;
