export const CASE_MANAGER = '/case-manager';
export const CASE_DETAILS = '/case-manager/:id';
export const FEEDBACK = '/feedback';
export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const RISK_SCORING = '/risk-scoring';
export const RISK_SETTINGS = '/risk-settings';
export const ACCOUNT = '/account';
export const INVESTIGATE = '/investigate';
export const ANALYSE = '/analyse';
export const QUICK_START = '/quick-start';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const API = '/api';
