/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'baseui/button';

import { DivPrimary } from '../ui';
import { withFirebase } from '../Firebase';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  const WithEmailVerification = props => {
    const authUser = useSelector(state => state.session.authUser);
    const [isSent, setIsSent] = useState(false);
    const onSendEmailVerification = () => {
      props.firebase.doSendEmailVerification().then(() => setIsSent(true));
    };

    return needsEmailVerification(authUser) ? (
      <DivPrimary>
        {isSent ? (
          <p>
            E-Mail confirmation sent: Check your E-Mails (Spam folder included)
            for a confirmation E-Mail. Refresh this page once you have confirmed
            your E-Mail.
          </p>
        ) : (
          <p>
            Verify your E-Mail: Check your E-Mails (Spam folder included) for a
            confirmation E-Mail or send another confirmation E-Mail. Refresh
            this page once you have confirmed your E-Mail.
          </p>
        )}

        <Button
          kind="secondary"
          onClick={onSendEmailVerification}
          disabled={isSent}
        >
          Send confirmation E-Mail
        </Button>
      </DivPrimary>
    ) : (
      <Component {...props} />
    );
  };

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
