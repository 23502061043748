import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'baseui';
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem,
} from 'baseui/header-navigation';
import { MotifLightTheme, MotifDarkTheme } from '@cylynx/motif';
import { Block } from 'baseui/block';
import { KIND } from 'baseui/button';
import { LinkButton } from '../ui';
import UserPopover from './UserPopover';
import MobileNav from './MobileNav';

import * as ROUTES from '../../constants/routes';

const Navigation = ({ location }) => {
  // const authUser = useContext(AuthUserContext);
  const authUser = useSelector(state => state.session.authUser);

  return (
    <>
      {authUser ? (
        <NavigationAuth location={location} authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )}
    </>
  );
};

// Do not show Blocklynx logo for investigate page
const NavigationAuth = ({ authUser, location }) => (
  <>
    <ThemeProvider
      theme={
        location.pathname.includes(ROUTES.INVESTIGATE)
          ? MotifDarkTheme
          : MotifLightTheme
      }
    >
      <StyledHeader>
        <StyledNavigationList $align={ALIGN.left}>
          <StyledNavigationItem>
            {/* {!location.pathname.includes(ROUTES.INVESTIGATE) && ( */}
            <LinkButton to={ROUTES.LANDING} kind={KIND.tertiary}>
              Blocklynx
            </LinkButton>
            {/* )} */}
          </StyledNavigationItem>
        </StyledNavigationList>
        <StyledNavigationList $align={ALIGN.center} />
        <Block display={['none', 'none', 'block']} alignSelf="center">
          <Block marginRight="60px">
            <StyledNavigationList $align={ALIGN.right}>
              <LinkButton to={ROUTES.CASE_MANAGER} kind={KIND.tertiary}>
                Case Manager
              </LinkButton>
              <LinkButton to={ROUTES.RISK_SCORING} kind={KIND.tertiary}>
                Risk Scoring
              </LinkButton>
              <LinkButton to={ROUTES.INVESTIGATE} kind={KIND.tertiary}>
                Investigate
              </LinkButton>
              <LinkButton to={ROUTES.ANALYSE} kind={KIND.tertiary}>
                Analyse
              </LinkButton>
              <LinkButton to={ROUTES.API} kind={KIND.tertiary}>
                API
              </LinkButton>
              <UserPopover authUser={authUser} />
            </StyledNavigationList>
          </Block>
        </Block>

        <Block display={['block', 'block', 'none']} alignSelf="center">
          <MobileNav />
        </Block>
      </StyledHeader>
    </ThemeProvider>
  </>
);

const NavigationNonAuth = () => (
  <StyledHeader>
    <StyledNavigationList $align={ALIGN.left}>
      <StyledNavigationItem>
        <LinkButton to={ROUTES.LANDING} kind={KIND.tertiary}>
          Blocklynx
        </LinkButton>
      </StyledNavigationItem>
    </StyledNavigationList>
    <StyledNavigationList $align={ALIGN.center} />
    <StyledNavigationList $align={ALIGN.right}>
      <LinkButton to={ROUTES.SIGN_IN} kind={KIND.tertiary}>
        Log In
      </LinkButton>
      <LinkButton to={ROUTES.SIGN_UP} kind={KIND.tertiary}>
        Sign Up
      </LinkButton>
      <LinkButton to={ROUTES.API} kind={KIND.tertiary}>
        API
      </LinkButton>
      <StyledNavigationItem />
    </StyledNavigationList>
  </StyledHeader>
);

const StyledHeader = ({ children }) => (
  <HeaderNavigation
    overrides={{
      Root: {
        style: ({ $theme }) => {
          return {
            borderBottomWidth: 0,
            paddingBottom: 0,
            backgroundColor: $theme.colors.backgroundPrimary,
          };
        },
      },
    }}
  >
    {children}
  </HeaderNavigation>
);

export default withRouter(Navigation);
