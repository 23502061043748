import * as React from 'react';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { Navigation } from 'baseui/side-navigation';
import { Button } from 'baseui/button';
import { Menu } from 'baseui/icon';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SideNavItems = [
  {
    title: 'Home',
    itemId: ROUTES.LANDING,
  },
  {
    title: 'Case Manager',
    itemId: ROUTES.CASE_MANAGER,
  },
  {
    title: 'Risk Scoring',
    itemId: ROUTES.RISK_SCORING,
  },
  {
    title: 'Analyse',
    itemId: ROUTES.ANALYSE,
  },
  {
    title: 'Account',
    itemId: ROUTES.ACCOUNT,
  },
  {
    title: 'Quick Start',
    itemId: ROUTES.QUICK_START,
  },
  {
    title: 'API',
    itemId: ROUTES.API,
  },
  {
    title: 'Log Out',
    itemId: 'Log Out',
  },
];

const MobileNav = ({ firebase, history, location }) => {
  return (
    <StatefulPopover
      content={({ close }) => (
        <Navigation
          items={SideNavItems}
          activeItemId={location.pathname}
          onChange={({ event, item }) => {
            event.preventDefault();
            if (item.itemId === 'Log Out') {
              firebase.doSignOut();
            } else {
              history.push(item.itemId);
            }
            close();
          }}
        />
      )}
      placement={PLACEMENT.bottomRight}
      focusLock
      returnFocus
      autoFocus
      overrides={{
        Body: {
          style: {
            zIndex: 10,
          },
        },
      }}
    >
      <Button kind="tertiary">
        <Menu />
      </Button>
    </StatefulPopover>
  );
};
export default withFirebase(withRouter(MobileNav));
