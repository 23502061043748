/* eslint-disable no-param-reassign */
// immer wraps around redux-toolkit so we can 'directly' mutate state'
import { createSlice } from '@reduxjs/toolkit';
import Firebase from '../Firebase';

const initialState = {
  authUser: null,
  firebase: new Firebase(),
};

const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setAuthUser(state, action) {
      state.authUser = action.payload;
    },
  },
});

export const { setAuthUser } = session.actions;

export default session.reducer;
