/* eslint-disable prefer-destructuring */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// immer wraps around redux-toolkit so we can 'directly' mutate state'
import { createSlice } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';
import omit from 'lodash/omit';

const initialState = {
  loading: true,
  queryEntity: {
    type: 'entity',
    entity: 'Kraken',
    token: 'All',
    currency: 'USD',
    addrHash: '',
    dateRange: [subMonths(new Date(), 1), new Date()],
  },
  queryWatchList: {
    token: 'All',
    currency: 'USD',
    dateRange: [subMonths(new Date(), 1), new Date()],
  },
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    addScore(state, action) {
      state.score = action.payload;
    },
    updateEntityState(state, action) {
      state.queryEntity = { ...initialState.queryEntity, ...action.payload };
    },
    updateWatchListState(state, action) {
      state.queryWatchList = {
        ...initialState.queryWatchList,
        ...action.payload,
      };
    },
  },
});

// Convert redux queryEntity to params
export const query2params = (queryEntity, excludeList = []) => {
  let params = {
    token: queryEntity.token,
    currency: queryEntity.currency,
    start_date: queryEntity.dateRange[0].toISOString().slice(0, 10),
    end_date: queryEntity.dateRange[1].toISOString().slice(0, 10),
  };

  if (queryEntity.type === 'entity') {
    params.entity = queryEntity.entity;
  } else {
    params.address = queryEntity.addrHash;
  }

  // Backend by default aggregates on all
  if (queryEntity.token === 'All') {
    params = omit(params, 'token');
  }

  if (excludeList.length > 0) {
    params = omit(params, excludeList);
  }
  return params;
};

export const {
  resetState,
  addScore,
  updateEntityState,
  updateWatchListState,
} = dashboard.actions;

export default dashboard.reducer;
