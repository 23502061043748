/* https://baseweb.design/components/input/#create/delete-tags */
import * as React from 'react';
import { useStyletron } from 'baseui';
import { Input, StyledInput } from 'baseui/input';
import { Tag, VARIANT } from 'baseui/tag';

// eslint-disable-next-line react/display-name
const InputReplacement = React.forwardRef(
  ({ tags, removeTag, ...restProps }, ref) => {
    const [css] = useStyletron();
    return (
      <div
        className={css({
          flex: '1 1 0%',
          flexWrap: 'wrap',
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {tags.map((tag, index) => (
          <Tag
            variant={VARIANT.solid}
            kind="accent"
            onActionClick={() => removeTag(tag)}
            key={index} // eslint-disable-line react/no-array-index-key
          >
            {tag}
          </Tag>
        ))}
        <StyledInput ref={ref} {...restProps} />
      </div>
    );
  }
);

const TagInput = ({ onTagChange, ...rest }) => {
  const [value, setValue] = React.useState('');
  const [tags, setTags] = React.useState([]);

  const handleTagChange = newTags => {
    setTags(newTags);
    if (onTagChange) {
      onTagChange(newTags);
    }
  };

  const addTag = tag => {
    handleTagChange([...tags, tag]);
  };

  const removeTag = tag => {
    handleTagChange(tags.filter(t => t !== tag));
  };

  const handleBlur = () => {
    if (!value) return;
    addTag(value);
    setValue('');
  };

  const handleKeyDown = event => {
    switch (event.keyCode) {
      // Enter
      case 13: {
        if (!value) return;
        addTag(value);
        setValue('');
        return;
      }
      // Tab
      case 9: {
        if (!value) return;
        addTag(value);
        setValue('');
        return;
      }
      // Backspace
      case 8: {
        if (value || !tags.length) return;
        removeTag(tags[tags.length - 1]);
      }
      // no default
    }
  };
  return (
    <Input
      placeholder={tags.length ? '' : 'Enter Search Terms'}
      value={value}
      onChange={e => setValue(e.currentTarget.value)}
      onBlur={handleBlur}
      overrides={{
        Input: {
          style: { width: 'auto', flexGrow: 1 },
          component: InputReplacement,
          props: {
            tags,
            removeTag,
            onKeyDown: handleKeyDown,
          },
        },
      }}
      {...rest}
    />
  );
};

export default TagInput;
