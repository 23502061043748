import React, { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { HeadingMedium } from 'baseui/typography';
import {
  STYLE_TYPE,
  StyledCheckmark,
  StyledInput,
  StyledRoot,
} from 'baseui/checkbox';

import { ChevronDown, ChevronRight, TriangleDown } from 'baseui/icon';
import DataTableComponent, { createTheme } from 'react-data-table-component';
import Loader from './Loader';

// Custom Base Web Checkbox which is controlled by the table component
// It needs to react to an onClick event which is incompatible with Base Web Checkbox
// which uses an onChange event
const BaseWebCheckBox = ({ inputRef, checked, ...rest }) => {
  const sharedProps = {
    $isFocused: false,
    $isFocusVisible: false,
    $isHovered: false,
    $isActive: false,
    $isError: false,
    $error: false,
    $checked: checked,
    $isIndeterminate: false,
    $required: false,
    $disabled: false,
    $value: false,
    $checkmarkType: STYLE_TYPE.default,
  };
  return (
    <StyledRoot data-baseweb="checkbox" {...sharedProps}>
      <StyledCheckmark role="checkbox" checked={checked} {...sharedProps} />
      <StyledInput
        checked={checked}
        type="checkbox"
        ref={inputRef}
        {...sharedProps}
        {...rest}
      />
    </StyledRoot>
  );
};

const CustomCheckBox = React.forwardRef(
  ({ onClick, checked, ...rest }, ref) => {
    return (
      <BaseWebCheckBox
        type="checkbox"
        inputRef={ref}
        onClick={onClick}
        checked={checked}
        {...rest}
      />
    );
  },
);

const styleMapper = (theme) => {
  // https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.js
  return {
    rows: {
      style: {
        ...theme.typography.ParagraphSmall,
        minHeight: theme.sizing.scale1200,
      },
    },
    headCells: {
      style: {
        ...theme.typography.LabelLarge,
      },
    },
    header: {
      style: {
        minHeight: '46px',
      },
    },
    contextMenu: {
      style: {
        ...theme.typography.ParagraphMedium,
        zIndex: 0,
      },
    },
    cells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
        wordBreak: 'break-word',
      },
    },
    pagination: {
      style: {
        color: theme.colors.contentPrimary,
        ...theme.typography.ParagraphSmall,
        minHeight: '56px',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
      },
    },
  };
};

const DataTable = ({
  title,
  paginationPerPage = 5,
  subHeaderComponent = false,
  ...rest
}) => {
  const [customStyles, setCustomStyles] = useState(false);
  const [, theme] = useStyletron();

  useEffect(() => {
    // Map styling to base web theme
    // https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/themes.js
    createTheme('baseweb', {
      text: {
        primary: theme.colors.contentPrimary,
        secondary: theme.colors.contentSecondary,
        disabled: theme.colors.contentStateDisabled,
      },
      background: {
        default: theme.colors.backgroundPrimary,
      },
      context: {
        background: theme.colors.backgroundSecondary,
      },
      striped: {
        default: theme.colors.backgroundSecondary,
      },
    });
    const style = styleMapper(theme);
    setCustomStyles(style);
  }, [theme]);

  return (
    <>
      {customStyles && (
        <DataTableComponent
          theme="baseweb"
          title={<HeadingMedium paddingLeft={0}>{title}</HeadingMedium>}
          noHeader={!title}
          customStyles={customStyles}
          selectableRowsComponent={CustomCheckBox}
          subHeader={!!subHeaderComponent}
          subHeaderAlign="left"
          subHeaderComponent={subHeaderComponent}
          expandableIcon={{
            collapsed: <ChevronRight size={24} />,
            expanded: <ChevronDown size={24} />,
          }}
          sortIcon={<TriangleDown />}
          paginationPerPage={paginationPerPage}
          paginationRowsPerPageOptions={[5, 10, 25, 50]}
          progressComponent={<Loader />}
          {...rest}
        />
      )}
    </>
  );
};

export default DataTable;
