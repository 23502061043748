import React from 'react';
import { withRouter } from 'react-router-dom';

import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { Button, KIND, SIZE } from 'baseui/button';
import { ChevronDown } from 'baseui/icon';
import { Avatar } from 'baseui/avatar';
import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

const ITEMS = [
  { label: 'Account' },
  { label: 'Quick Start Guide' },
  { label: 'Log Out' },
];

const RouteUserPopover = (firebase, history, label) => {
  if (label === 'Quick Start Guide') {
    history.push(ROUTES.QUICK_START);
  }
  if (label === 'Account') {
    history.push(ROUTES.ACCOUNT);
  }
  if (label === 'Log Out') {
    firebase.doSignOut();
  }
};

const UserPopover = ({ firebase, history, authUser }) => (
  <StatefulPopover
    content={({ close }) => (
      <StatefulMenu
        items={ITEMS}
        onItemSelect={(item) => {
          RouteUserPopover(firebase, history, item.item.label);
          close();
        }}
        overrides={{
          List: {
            style: {
              width: '200px',
            },
          },
        }}
      />
    )}
    placement={PLACEMENT.bottomRight}
    focusLock
    returnFocus
    autoFocus
    overrides={{
      Body: {
        style: {
          zIndex: 10,
        },
      },
    }}
  >
    <Button
      startEnhancer={
        <Avatar
          name={authUser.username}
          size="scale900"
          src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${authUser.username}`}
        />
      }
      kind={KIND.tertiary}
      size={SIZE.compact}
      endEnhancer={<ChevronDown size={24} />}
      overrides={{
        BaseButton: {
          style: () => {
            return {
              'padding-bottom': '5px',
              'padding-top': '5px',
            };
          },
        },
      }}
    >
      {authUser.username}
    </Button>
  </StatefulPopover>
);

export default withFirebase(withRouter(UserPopover));
