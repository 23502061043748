/* eslint-disable no-param-reassign */
import app from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import axios from 'axios';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */
    this.fieldPath = app.firestore.FieldPath;
    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.analytics = app.analytics();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API *** //

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Analytics API *** //
  logPage = path => this.analytics.logEvent('page_view', { page_path: path });

  logEvent = (event, params) =>
    params
      ? this.analytics.logEvent(event, params)
      : this.analytics.logEvent(event);

  // *** Merge Auth and DB User and Analytics API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // Set token as default header in axios authorization bearer
            axios.interceptors.request.use(
              // eslint-disable-next-line no-shadow
              async config => {
                config.headers.Authorization = `Bearer ${await this.auth.currentUser.getIdToken()}`;
                return config;
              },
              error => {
                return Promise.reject(error);
              },
            );

            // Set analytics userid
            this.analytics.setUserId(authUser.uid);
            this.analytics.setUserProperties({ userId: authUser.uid });

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              tokenId: authUser.tokenId,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  baseDB = () => this.db.collection('organization').doc('public');

  // *** User API ***

  user = uid => this.users().doc(uid);

  users = () => this.baseDB().collection('users');

  // *** Watchlist API (each user has one identified by his uid) ***

  watchList = uid => this.watchLists().doc(uid);

  watchLists = () => this.baseDB().collection(`watchList`);

  // *** Message API (user specific)***

  message = (uid, messageid) => this.messages(uid).doc(messageid);

  messages = uid =>
    this.baseDB()
      .collection(`messages`)
      .where('uid', '==', uid);

  // *** Case Manager API (user specific)***

  caseUser = uid => this.cases().where('uid', '==', uid);

  cases = () => this.baseDB().collection('cases');

  caseMessages = caseid =>
    this.cases()
      .doc(caseid)
      .collection('messages');

  // *** Query History API (user specific)***

  queriesUser = uid => this.queries().where('uid', '==', uid);

  queries = () => this.baseDB().collection(`queries`);

  // *** Risk Settings API (user specific)***

  riskProfilesUser = uid => this.riskProfiles().where('uid', '==', uid);

  riskProfiles = () => this.baseDB().collection('riskProfiles');

  // *** Graph Storage API (user specific)***

  baseGCS = () => this.storage.ref().child('organization/public/');

  graphIdRef = (uid, graphId) => this.graphRef(uid).child(graphId);

  graphRef = uid => this.baseGCS().child(`graph/${uid}`);
}

export default Firebase;
