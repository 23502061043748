import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import { FirebaseContext } from './components/Firebase';
import App from './pages/App';
import store from './redux/store';

ReactDOM.render(
  <FirebaseContext.Provider value={store.getState().session.firebase}>
    <Provider store={store}>
      <App />
    </Provider>
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
