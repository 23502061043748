/* eslint-disable prefer-destructuring */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// immer wraps around redux-toolkit so we can 'directly' mutate state'
import { createSlice } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';

const initialState = {
  queryRiskScore: {
    token: 'All',
    riskCategory: [],
    dateRange: [subMonths(new Date(), 1), new Date()],
  },
};

const riskScoring = createSlice({
  name: 'riskScoring',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    updateQuery(state, action) {
      const { field, value } = action.payload;
      state.queryRiskScore[field] = value;
    },
  },
});

export const { resetState, updateQuery } = riskScoring.actions;

export default riskScoring.reducer;
