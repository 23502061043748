import React, { Suspense, lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Switch,
} from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ReactQueryDevtools } from 'react-query-devtools';
import { MotifLightTheme } from '@cylynx/motif';
// import { CustomLightTheme } from '../../theme';

import { Loader } from '../../components/ui';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../../components/Session';
import Navigation from '../../components/Navigation';
import { withFirebase } from '../../components/Firebase';

const engine = new Styletron();

const LandingPage = lazy(() => import('../Landing'));
const SignUpPage = lazy(() => import('../SignUp'));
const SignInPage = lazy(() => import('../SignIn'));
const PasswordForgetPage = lazy(() => import('../PasswordForget'));
const FeedbackPage = lazy(() => import('../Feedback'));
const CaseManagerPage = lazy(() => import('../CaseManager'));
const RiskScoringPage = lazy(() => import('../RiskScoring'));
const RiskSettingsPage = lazy(() => import('../RiskSettings'));
const AnalysePage = lazy(() => import('../Analyse'));
const AccountPage = lazy(() => import('../Account'));
// const AdminPage = lazy(() => import('../Admin'));
const InvestigatePage = lazy(() => import('../Investigate'));
const ApiDocsPage = lazy(() => import('../ApiDocs'));
const QuickStartPage = lazy(() => import('../QuickStart'));

const App = () => (
  <Router>
    <StyletronProvider value={engine}>
      <BaseProvider theme={MotifLightTheme}>
        <Navigation />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route
              path={ROUTES.PASSWORD_FORGET}
              component={PasswordForgetPage}
            />
            <Route path={ROUTES.FEEDBACK} component={FeedbackPage} />
            <Route path={ROUTES.INVESTIGATE} component={InvestigatePage} />
            <Route path={ROUTES.ANALYSE} component={AnalysePage} />
            <Route path={ROUTES.CASE_MANAGER} component={CaseManagerPage} />
            <Route path={ROUTES.RISK_SCORING} component={RiskScoringPage} />
            <Route path={ROUTES.RISK_SETTINGS} component={RiskSettingsPage} />
            <Route path={ROUTES.API} component={ApiDocsPage} />
            <Route path={ROUTES.QUICK_START} component={QuickStartPage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            {/* <Route path={ROUTES.ADMIN} component={AdminPage} /> */}
          </Switch>
        </Suspense>
        <ReactQueryDevtools initialIsOpen />
        <AnalyticsLogger />
      </BaseProvider>
    </StyletronProvider>
  </Router>
);

const Logger = ({ firebase }) => {
  const location = useLocation();
  // Log on first render and when the pathname changes
  useEffect(() => {
    firebase.logPage(location.pathname);
  }, [firebase, location.pathname]);

  return null;
};

const AnalyticsLogger = withFirebase(Logger);

export default withAuthentication(App);
