export const multiplyArr = (arr1, arr2) => {
  // Dot product of two arrays
  let result = 0;
  for (let i = 0; i < arr1.length; i += 1) {
    result += arr1[i] * arr2[i];
  }
  return result;
};

export const roundToTwo = num =>
  typeof num === 'string' ? parseInt(num, 10).toFixed(2) : num.toFixed(2);

/**
 * Convert a json data object to a suitable format for plotting in echarts
 * Each field key will be added as a [date, field] pair
 *
 * @param {*} data
 * @param {{key:string, name:string}[]} fields Array of key and name object - correspond to the number of lines in the graph
 * @param {string} [type='line']
 * @return {*}
 */
export const data2chart = (data, fields, type = 'line') => {
  const results = [];
  for (const field of fields) {
    const graphObj = {
      type,
      data: data.map(o => [o.date, o[field.key]]),
    };
    if (field.name) {
      graphObj.name = field.name;
    }
    results.push(graphObj);
  }
  return results;
};

/**
 * Formats a currency based on a given currency code
 * To use: currencyFormatter("SGD").format(12345)
 * https://www.iban.com/currency-codes
 *
 * @param {string} [currency='USD']
 */
export const currencyFormatter = (currency = 'USD') =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    notation: 'compact',
    MinimumSignificantDigits: 3,
  });

export const processScoreVector = (categories, scoreVector) => {
  // Map score vector to categories
  // For PieChart
  // Output needs to be an array with object {name, value}
  const results = Object.keys(categories)
    .map((key, index) => ({
      name: key,
      value: parseFloat(roundToTwo(scoreVector[index] * 100)),
    }))
    .filter(item => item.value > 0);
  return results;
};

export const timeConverter = timestamp => {
  // Unix timestamp in milliseconds
  const a = new Date(timestamp);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = a.getUTCFullYear();
  const month = months[a.getUTCMonth()];
  const date = a.getUTCDate();
  const hour = a.getUTCHours();
  const min =
    a.getUTCMinutes() < 10 ? `0${a.getUTCMinutes()}` : a.getUTCMinutes();
  const sec =
    a.getUTCSeconds() < 10 ? `0${a.getUTCSeconds()}` : a.getUTCSeconds();
  const time = `${date} ${month} ${year} ${hour}:${min}:${sec}`;
  return time;
};

// https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
export const dateToString = date => {
  const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
  return dateString;
};

export const deriveRiskCategory = riskScore => {
  if (riskScore >= 80) {
    return 'Very High';
  }
  if (riskScore >= 60) {
    return 'High';
  }
  if (riskScore >= 40) {
    return 'Medium';
  }
  if (riskScore >= 20) {
    return 'Low';
  }
  if (riskScore >= 0) {
    return 'Very Low';
  }
  return 'N.A.';
};

export const removePunctuation = text => {
  // https://stackoverflow.com/questions/4328500/how-can-i-strip-all-punctuation-from-a-string-in-javascript-using-regex#:~:text=str%20%3D%20str.,adjacent%20characters%20to%20single%20spaces.
  return text.replace(/[^\w\s]|_/g, '').replace(/\s+/g, ' ');
};

export const createKeywords = searchString => {
  const searchArray = removePunctuation(searchString)
    .toLowerCase()
    .split(' ')
    .filter(str => {
      return str.length >= 3;
    });
  return [...new Set(searchArray)];
};

const capFirst = string => string.charAt(0).toUpperCase() + string.slice(1);

const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;

export const generateName = () => {
  const adjectives = [
    'adamant',
    'adroit',
    'animistic',
    'antic',
    'arcadian',
    'baleful',
    'bellicose',
    'bilious',
    'boorish',
    'calamitous',
    'caustic',
    'cerulean',
    'defamatory',
    'didactic',
    'dowdy',
    'efficacious',
    'effulgent',
    'egregious',
    'endemic',
    'fastidious',
    'feckless',
    'fecund',
    'fulsome',
    'garrulous',
    'guileless',
    'heuristic',
    'histrionic',
    'hubristic',
    'incendiary',
    'insidious',
    'insolent',
    'intransigent',
    'inveterate',
    'invidious',
    'irksome',
    'jejune',
    'jocular',
    'judicious',
    'limpid',
    'loquacious',
    'luminous',
    'mannered',
    'mendacious',
    'mordant',
    'munificent',
    'nefarious',
    'noxious',
    'obtuse',
    'parsimonious',
    'pendulous',
    'pernicious',
    'pervasive',
    'petulant',
    'platitudinous',
    'precipitate',
    'propitious',
    'puckish',
    'querulous',
    'quiescent',
    'rebarbative',
    'recalcitant',
    'redolent',
    'risible',
    'ruminative',
    'sagacious',
    'sartorial',
    'sclerotic',
    'serpentine',
    'spasmodic',
    'strident',
    'taciturn',
    'tenacious',
    'tremulous',
    'trenchant',
    'turbulent',
    'turgid',
    'ubiquitous',
    'voracious',
    'wheedling',
    'withering',
    'zealous',
  ];
  const nouns = [
    'lynx',
    'ninja',
    'chair',
    'pancake',
    'statue',
    'unicorn',
    'rainbows',
    'laser',
    'senor',
    'bunny',
    'captain',
    'nibblets',
    'cupcake',
    'durians',
    'carrot',
    'gnomes',
    'glitter',
    'potato',
    'salad',
    'mermaid',
    'merlion',
    'marsupials',
    'beets',
    'toilet',
    'kraken',
    'starfruit',
    'wheelwright',
    'woodpecker',
  ];
  const name = `${capFirst(
    adjectives[getRandomInt(0, adjectives.length)],
  )} ${capFirst(nouns[getRandomInt(0, nouns.length)])}`;
  return name;
};
