import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { investigateReducer } from '@cylynx/motif';

// import { investigateReducer } from '../components/Investigate/redux';
import sessionReducer from '../components/Session/sessionSlice';
import riskProfileReducer from '../components/RiskProfile/riskProfileSlice';
import riskScoringReducer from '../components/RiskScoring/riskScoringSlice';
import caseManagerReducer from '../components/CaseManager/caseManagerSlice';
import dashboardReducer from '../components/DashboardQuery/dashboardSlice';

// Redux thunk is automatically added as default middleware
// graphReducer is enhanced with undo & redo functions
const store = configureStore({
  reducer: combineReducers({
    session: sessionReducer,
    riskProfile: riskProfileReducer,
    riskScoring: riskScoringReducer,
    caseManager: caseManagerReducer,
    dashboard: dashboardReducer,
    investigate: investigateReducer,
  }),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

export default store;
