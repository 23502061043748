/* eslint-disable import/prefer-default-export */
export const getCurrentRiskProfile = (firebase, authUser) => {
  return firebase
    .riskProfilesUser(authUser.uid)
    .orderBy('createdAt', 'desc')
    .limit(1)
    .get()
    .then(snapshot => {
      const results = [];
      snapshot.forEach(doc => results.push({ ...doc.data() }));
      return {
        score: results[0].CATEGORIES,
        profileName: results[0].profileName,
      };
    })
    .catch(err => console.log(err));
};
