/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// immer wraps around redux-toolkit so we can 'directly' mutate state'
import { createSlice } from '@reduxjs/toolkit';
import { getCurrentRiskProfile } from '../../utils';

const initialState = {
  current: {
    score: null,
    profileName: null,
  },
};

const riskProfile = createSlice({
  name: 'riskProfile',
  initialState,
  reducers: {
    setRiskProfile(state, action) {
      state.current = action.payload;
    },
  },
});

// Fetch action functions
export const initializeRiskProfile = (firebase, authUser) => async dispatch => {
  const query = getCurrentRiskProfile(firebase, authUser);
  return query.then(results => {
    dispatch(setRiskProfile(results));
  });
};

export const { setRiskProfile } = riskProfile.actions;

export default riskProfile.reducer;
