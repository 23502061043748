import * as React from 'react';

type VideoProps = {
  title: string;
  src: string;
  width?: string;
};

const Video = ({ title, src, width = '100%' }: VideoProps) => {
  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        width,
      }}
    >
      <iframe
        title={title || src}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={src}
        allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
        frameBorder="0"
      />
    </div>
  );
};

export default Video;
