import React from 'react';
import { useStyletron, styled } from 'baseui';

import { Block } from 'baseui/block';

const DivPrimary = ({
  align,
  width,
  height,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  children,
}) => {
  const [, theme] = useStyletron();
  return (
    <Block
      paddingTop={paddingTop || '30px'}
      paddingBottom={paddingBottom || '30px'}
      paddingLeft={paddingLeft || ['20px', '20px', '10%', '10%']}
      paddingRight={paddingRight || ['20px', '20px', '10%', '10%']}
      width={width || 'auto'}
      height={height || 'auto'}
      margin="0 auto"
      backgroundColor={theme.colors.backgroundPrimary}
      overrides={{
        Block: {
          style: { textAlign: align || 'left' },
        },
      }}
    >
      {children}
    </Block>
  );
};

const DivSecondary = ({
  align,
  width,
  height,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  backgroundColor,
  children,
}) => {
  const [, theme] = useStyletron();
  return (
    <Block
      paddingTop={paddingTop || '30px'}
      paddingBottom={paddingBottom || '30px'}
      paddingLeft={paddingLeft || ['20px', '20px', '10%', '10%']}
      paddingRight={paddingRight || ['20px', '20px', '10%', '10%']}
      width={width || 'auto'}
      height={height || 'auto'}
      margin="0 auto"
      backgroundColor={backgroundColor || theme.colors.backgroundSecondary}
      overrides={{
        Block: {
          style: { textAlign: align || 'left' },
        },
      }}
    >
      {children}
    </Block>
  );
};

const DivBox = ({
  align,
  width,
  height,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  backgroundColor,
  children,
}) => {
  const [, theme] = useStyletron();
  return (
    <Block
      paddingTop={paddingTop || '24px'}
      paddingBottom={paddingBottom || '24px'}
      paddingLeft={paddingLeft || ['10px', '10px', '5%', '5%']}
      paddingRight={paddingRight || ['10px', '10px', '5%', '5%']}
      width={width || 'auto'}
      height={height || 'auto'}
    >
      <Block
        paddingTop={paddingTop || '36px'}
        paddingBottom={paddingBottom || '36px'}
        paddingLeft={paddingLeft || ['10px', '10px', '5%', '5%']}
        paddingRight={paddingRight || ['10px', '10px', '5%', '5%']}
        width={width || 'auto'}
        height={height || 'auto'}
        margin="0 auto"
        backgroundColor={backgroundColor || theme.colors.backgroundPrimary}
        overrides={{
          Block: {
            style: {
              textAlign: align || 'left',
              borderTop: '8px solid teal',
              boxShadow: '0 1px 3px hsla(0, 0%, 0%, 0.2)',
              borderRadius: '5px',
            },
          },
        }}
      >
        {children}
      </Block>
    </Block>
  );
};

const Centered = styled('div', {
  margin: '0 auto',
  paddingLeft: '10%',
  paddingRight: '10%',
});

export { DivBox, DivPrimary, DivSecondary, Centered };
