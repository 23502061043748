import React from 'react';
import { Button, ButtonProps } from 'baseui/button';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export * from 'baseui/button';

type FullButtonProps = ButtonProps & {
  children: React.ReactNode;
  width?: string | number | (string & {});
};

type LinkButtonDefaultProps = ButtonProps &
  RouteComponentProps & {
    to: string;
  };

type Ref = HTMLButtonElement;

// Button which streches the full width of the container
export const FullButton = React.forwardRef<Ref, FullButtonProps>(
  ({ children, width = '100%', ...rest }, ref) => (
    <Button
      ref={ref}
      overrides={{
        BaseButton: {
          style: {
            width,
          },
        },
      }}
      {...rest}
    >
      {children}
    </Button>
  ),
);

/* https://stackoverflow.com/questions/42463263/wrapping-a-react-router-link-in-an-html-button/49439893 */
const LinkButtonDefault = (props: LinkButtonDefaultProps) => {
  const { history, to, onClick, staticContext, children, ...rest } = props;
  return (
    <Button
      {...rest}
      onClick={(event) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onClick && onClick(event);
        history.push(to);
      }}
    >
      {children}
    </Button>
  );
};

export const LinkButton = withRouter(LinkButtonDefault);
