import * as React from 'react';
import { StatefulMenu, OptionProfile } from 'baseui/menu';

// const ITEMS = [...new Array(10)].map(() => ({
//   body: 'The quick brown fox jumps over the lazy dog',
//   subtitle: 'hello world',
//   title: 'Jane Smith',
//   imgUrl: 'https://via.placeholder.com/60x60',
// }));

const MessageList = ({ height, messages }) => {
  return (
    <StatefulMenu
      items={messages}
      overrides={{
        List: {
          style: {
            height: height || 'auto',
            boxShadow: 0,
          },
        },
        Option: {
          component: OptionProfile,
          props: {
            getProfileItemLabels: ({ title, subtitle, body }) => ({
              title,
              subtitle,
              body,
            }),
            getProfileItemImg: item => item.imgUrl,
            getProfileItemImgText: item => item.title,
            overrides: {
              ProfileImg: {
                style: {
                  height: '60px',
                  width: '60px',
                },
              },
            },
          },
        },
      }}
    />
  );
};

export default MessageList;
