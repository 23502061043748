/* eslint-disable prefer-destructuring */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// immer wraps around redux-toolkit so we can 'directly' mutate state'
import { createSlice } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';

const initialState = {
  queryCases: {
    status: 'active',
    searchText: [],
    dateRange: [subMonths(new Date(), 3), new Date()],
  },
  caseModal: {
    open: false,
  },
  detailsModal: {
    caseId: null,
    caseDetails: {},
    caseResources: [],
  },
  loading: false,
};

const caseManager = createSlice({
  name: 'caseManager',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    resetCreatorModal(state) {
      state.creatorModal = initialState.creatorModal;
    },
    fetchBegin(state) {
      state.loading = true;
      state.modalMsg = '';
      state.modalOpen = false;
    },
    fetchError(state, action) {
      state.loading = false;
      state.modalMsg = action.payload.message;
      state.modalOpen = true;
    },
    fetchDone(state) {
      state.loading = false;
    },
    updateQuery(state, action) {
      const { field, value } = action.payload;
      state.queryCases[field] = value;
    },
    setCaseId(state, action) {
      state.detailsModal.caseId = action.payload;
    },
    setCaseDetails(state, action) {
      state.detailsModal.caseDetails = action.payload;
    },
    setCaseResources(state, action) {
      state.detailsModal.caseResources = action.payload;
    },
    addCaseResource(state, action) {
      state.detailsModal.caseResources.push(action.payload);
    },
    openCaseModal(state) {
      state.caseModal.open = true;
    },
    closeCaseModal(state) {
      state.caseModal.open = false;
    },
  },
});

export const {
  resetState,
  setCaseId,
  openCaseModal,
  closeCaseModal,
  setCaseDetails,
  setCaseResources,
  addCaseResource,
  updateQuery,
} = caseManager.actions;

export default caseManager.reducer;
