/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';

const withAuthorization = condition => Component => {
  const WithAuthFirebase = props => {
    const authUser = useSelector(state => state.session.authUser);
    useEffect(() => {
      const listener = props.firebase.onAuthUserListener(
        // eslint-disable-next-line no-shadow
        authUser => {
          if (!condition(authUser)) {
            props.history.push(ROUTES.SIGN_IN);
          }
        },
        () => props.history.push(ROUTES.SIGN_IN),
      );
      return () => listener();
    }, [props.firebase, props.history]);

    return condition(authUser) ? <Component {...props} /> : null;
  };

  return compose(withRouter, withFirebase)(WithAuthFirebase);
};

export default withAuthorization;
