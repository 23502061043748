import React from 'react';
import { useStyletron } from 'baseui';

// https://stackoverflow.com/questions/5214127/css-technique-for-a-horizontal-line-with-words-in-the-middle
const Divider = ({ children }) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        overflow: 'hidden',
        textAlign: 'center',
        marginTop: theme.sizing.scale500,
        marginBottom: theme.sizing.scale500,
        ':before': {
          backgroundColor: theme.colors.contentTertiary,
          content: "''",
          display: 'inline-block',
          height: '1px',
          position: 'relative',
          verticalAlign: 'middle',
          width: '50%',
          right: '1em',
          marginLeft: '-50%',
        },
        ':after': {
          backgroundColor: theme.colors.contentTertiary,
          content: "''",
          display: 'inline-block',
          height: '1px',
          position: 'relative',
          verticalAlign: 'middle',
          width: '50%',
          left: '1em',
          marginRight: '-50%',
        },
      })}
    >
      {children}
    </div>
  );
};

export default Divider;
